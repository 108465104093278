import React, {useContext} from "react";
import {
  Avatar,
  Link,
  Box,
  Divider,
  ListItemAvatar,
  Theme,
  Hidden, ListItemButton
} from '@mui/material';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import {CompanyContext} from "../../../../../../contexts/CompanyContext";

interface Company {
  cik: string;
  tickerSymbol: string;
  name: string;
  domain: string;
}

interface Props {
  companies: Company[];
  onClose: () => void
}

const CompanyListItem: React.FC<Props> = ({companies, onClose}) => {

  const {selectCompany} = useContext(CompanyContext);

  const renderedCompanies = companies.map((company) => (
    <React.Fragment key={'Company' + company.cik}>
      <ListItemButton onClick={() => {
        selectCompany({domain: company.domain, name: company.name, ticker: company.tickerSymbol});
        onClose();
      }}>
        <Hidden smDown>
          <ListItemAvatar>
            <Avatar
              variant={"rounded"} alt={company.tickerSymbol}
              src={"https://logo.clearbit.com/" + company.domain + "?size=160"}
              sx={{
                background: (theme: Theme) =>
                  theme.palette.secondary.main
              }}
            >
              <FindInPageTwoToneIcon/>
            </Avatar>
          </ListItemAvatar>
        </Hidden>
        <Box flex="1">
          <Box display="flex" justifyContent="space-between">
            <Link
              href="#"
              underline="hover"
              variant="body2"
            >
              {company.name} (${company.tickerSymbol})
            </Link>
          </Box>
        </Box>
        <ChevronRightTwoToneIcon/>
      </ListItemButton>
      <Divider sx={{my: 1}} component="li"/>
    </React.Fragment>
  ));

  return (
    <>
      {renderedCompanies}
    </>
  );
}

export default CompanyListItem;
