import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  axisClasses,
  BarPlot,
  LinePlot,
  ResponsiveChartContainer,
  cheerfulFiestaPalette,
  ChartsLegend,
  ChartsTooltip,
  ChartsAxisHighlight,
  ChartsAxis,
  ChartsReferenceLine
} from '@mui/x-charts';
import {
  Theme,
  Container,
  Grid,
  Card,
  Typography,
  Avatar,
  ToggleButton, ToggleButtonGroup,
  Divider, Link
} from '@mui/material';
import Footer from 'src/components/Footer';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import { CompanyContext } from '../../../contexts/CompanyContext';
import SuspenseLoader from '../../../components/SuspenseLoader';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import {
  FinanceDataMillionValueFormatter,
  FinanceDataPercentageFormatter,
  FinanceDataValueFormatter
} from '../Formatter';
import { useFetchWithAuth } from '../../../auth0/useFetchWithAuth';

interface Revenues {
  xaxisLabels: string[];

  revenueTtmData: number[];
  costOfRevenueTtmData: number[];
  grossProfitTtmData: number[];

  netIncomeTtmData: number[];
  ebitTtmData: number[];
  ebitdaTtmData: number[];

  ocfTtmData: number[];
  capexTtmData: number[];
  fcfTtmData: number[];

  revenueData: number[];
  costOfRevenueData: number[];
  grossProfitData: number[];

  netIncomeData: number[];
  ebitData: number[];
  ebitdaData: number[];

  ocfData: number[];
  capexData: number[];
  fcfData: number[];

  assets: number[];
  cashAndCashEquivalents: number[];
  totalDebt: number[];

  roe: number[];
  roic: number[];
  cfroic: number[];

  numberOfShares: number[];

  epsPerQuarter: number[];
  epsTtmPerQuarter: number[];

  grossProfitMargin: number[];
  grossProfitMarginTtm: number[];

  netIncomeMargin: number[];
  netIncomeMarginTtm: number[];

  ebitdaMargin: number[];
  ebitdaMarginTtm: number[];

  fcfMargin: number[];
  fcfMarginTtm: number[];

  operatingMargin: number[];
  operatingMarginTtm: number[];

  equity: number[];
  equityTtm: number[];

  investedCapital: number[];

  opExData: number[];
  opExDataTtm: number[];

  sga: number[];
  sgaTtm: number[];

  rnd: number[];
  rndTtm: number[];

  sellingMarketing: number[];
  sellingMarketingTtm: number[];

  gna: number[];
  gnaTtm: number[];

  opExMargin: number[];
  opExMarginTtm: number[];

  capExMargin: number[];
  capExMarginTtm: number[];

  dividends: number[];
  dividendsTtm: number[];

  payoutRatio: number[];
  payoutRatioTtm: number[];

  epsGrowth: number[];
  epsGrowthTtm: number[];

  netCashOpExCapExTtm: number[];

  dividendYieldTtm: number[];

  revenueGrowthRateYoY: number[];
  ruleOf40: number[];
}

function ChartsRevenue() {

  const { selectedCompany } = useContext(CompanyContext);
  const [revenues, setRevenues] = useState<Revenues | undefined>(undefined);
  const [period, setPeriod] = useState('ttm');
  const [section, setSection] = useState('topline');
  const fetchWithAuth = useFetchWithAuth();

  const handlePeriodChange = (event: React.MouseEvent<HTMLElement>, newPeriod: string) => {
    if (newPeriod != null) {
      setPeriod(newPeriod);
    }
    console.log(event, newPeriod);
  };

  const handleSectionChange = (event: React.MouseEvent<HTMLElement>, newSection: string) => {
    if (newSection != null) {
      setSection(newSection);
    }
    console.log(event, newSection);
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchWithAuth<Revenues>('/api/revenues?ticker=' + selectedCompany.ticker); // Replace with your API endpoint
      setRevenues(response);
    };
    fetchData().then();
  }, [selectedCompany.ticker]);

  if (!revenues) {
    return (<SuspenseLoader />);
  }

  function switchResult(section: string) {
    switch (section) {
      default:
        return [];
      case 'topline':
        return [{
          label: 'Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.grossProfitTtmData : revenues.grossProfitData.slice(3)
        }, {
          label: 'Cost of Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.costOfRevenueTtmData : revenues.costOfRevenueData.slice(3)
        }, {
          label: 'Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.revenueTtmData : revenues.revenueData.slice(3)
        }];
      case 'profit':
        return [{
          label: 'Net Income' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.netIncomeTtmData : revenues.netIncomeData.slice(3)
        }, {
          label: 'EBIT' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.ebitTtmData : revenues.ebitData.slice(3)
        }, {
          label: 'EBITDA' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.ebitdaTtmData : revenues.ebitdaData.slice(3)
        }];
      case 'margins':
        return [{
          type: 'line',
          label: 'Gross Profit Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.grossProfitMarginTtm : revenues.grossProfitMargin.slice(3)
        }, {
          type: 'line',
          label: 'EBITDA Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.ebitdaMarginTtm : revenues.ebitdaMargin.slice(3)
        }, {
          type: 'line',
          label: 'Operating Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.operatingMarginTtm : revenues.operatingMargin.slice(3)
        }, {
          type: 'line',
          label: 'Net Income Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.netIncomeMarginTtm : revenues.netIncomeMargin.slice(3)
        }, {
          type: 'line',
          label: 'FCF Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.fcfMarginTtm : revenues.fcfMargin.slice(3)
        }];
      case 'cash':
        return [{
          label: 'Operating Cashflow' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.ocfTtmData : revenues.ocfData.slice(3)
        }, {
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.capexTtmData : revenues.capexData.slice(3)
        }, {
          label: 'Free Cashflow' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.fcfTtmData : revenues.fcfData.slice(3)
        }];
      case 'balanceSheet':
        return [{
          label: 'Total Debt',
          data: revenues.totalDebt.slice(3)
        }, {
          label: 'Cash And Cash Equivalents',
          data: revenues.cashAndCashEquivalents.slice(3)
        }, {
          label: 'Assets',
          data: revenues.assets.slice(3)
        }];
      case 'performance':
        return [{
          type: 'line',
          label: 'ROE',
          data: revenues.roe.slice(3)
        }, {
          type: 'line',
          label: 'ROIC',
          data: revenues.roic.slice(3)
        }, {
          type: 'line',
          label: 'CFROIC',
          data: revenues.cfroic
        }];
      case 'eps':
        return [{
          label: 'EPS' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.epsTtmPerQuarter : revenues.epsPerQuarter.slice(3)
        }];
      case 'shares':
        return [{
          label: 'Shares Outstanding',
          data: revenues.numberOfShares.slice(3)
        }];
      case 'equity':
        return [{
          label: 'Equity' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.equityTtm.slice(3) : revenues.equity.slice(3)
        }];
      case 'investedCapital':
        return [{
          label: 'Invested Capital',
          data: revenues.investedCapital.slice(3)
        }];
      case 'operationalLeverage':
        return [{
          type: 'line',
          label: 'Revenue' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.revenueTtmData : revenues.revenueData.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.capexTtmData : revenues.capexData.slice(3)
        }, {
          type: 'line',
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExDataTtm : revenues.opExData.slice(3)
        }];
      case 'opExVsGrossProfit':
        return [{
          label: 'Gross Profit' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.grossProfitTtmData : revenues.grossProfitData.slice(3)
        }, {
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExDataTtm : revenues.opExData.slice(3)
        }, {
          label: 'Selling General and Administrative' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.sgaTtm : revenues.sga.slice(3)
        }, {
          label: 'Research & Development' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.rndTtm : revenues.rnd.slice(3)
        }];
      case 'opExDistribution':
        return [{
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExDataTtm : revenues.opExData.slice(3)
        }, {
          label: 'Selling General and Administrative' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.sgaTtm : revenues.sga.slice(3)
        }, {
          label: 'Research & Development' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.rndTtm : revenues.rnd.slice(3)
        }, {
          label: 'Selling & Marketing Expense TTM' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.sellingMarketingTtm : revenues.sellingMarketing.slice(3)
        }, {
          label: 'General & Administrative Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.gnaTtm : revenues.gna.slice(3)
        }];
      case 'opExVsCapEx':
        return [{
          type: 'line',
          label: 'Operating Expense' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExDataTtm : revenues.opExData.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.capexTtmData : revenues.capexData.slice(3)
        }];
      case 'opExVsCapExMargin':
        return [{
          type: 'line',
          label: 'Operating Expense Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.opExMarginTtm : revenues.opExMargin.slice(3)
        }, {
          type: 'line',
          label: 'Capital Expenditures Margin' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.capExMarginTtm : revenues.capExMargin.slice(3)
        }];
      case 'dividends':
        return [{
          label: 'Dividends' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.dividendsTtm : revenues.dividends.slice(3)
        }];
      case 'payoutRatio':
        return [{
          label: 'Payout Ratio' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.payoutRatioTtm : revenues.payoutRatio.slice(3)
        }];
      case 'epsGrowth':
        return [{
          type: 'line',
          label: 'EPS Growth' + (period == 'ttm' ? ' TTM' : ''),
          data: period == 'ttm' ? revenues.epsGrowthTtm : revenues.epsGrowth.slice(3)
        }];
      case 'netCashOpExCapEx':
        return [{
          type: 'line',
          label: 'Net Cash / OpEx + CapEx TTM',
          data: revenues.netCashOpExCapExTtm
        }];
      case 'dividendYield':
        return [{
          type: 'line',
          label: 'Dividend Yield TTM',
          data: revenues.dividendYieldTtm
        }];
      case 'ruleOf40':
        return [{
          type: 'line',
          label: 'Revenue Growth Rate YoY',
          data: revenues.revenueGrowthRateYoY.slice(revenues.revenueGrowthRateYoY.length - 16)
        }, {
          type: 'line',
          label: 'FCF Margin',
          data: revenues.fcfMargin.slice(revenues.fcfMargin.length - 16)
        }, {
          type: 'line',
          label: 'Rule of 40',
          data: revenues.ruleOf40.slice(revenues.ruleOf40.length - 16)
        }];
    }
  }

  const series = switchResult(section);

  return (
    <>
      <Helmet>
        <title>Charts</title>
      </Helmet>

      <PageTitleWrapper>
        <Grid container alignItems="center">
          <Grid>
            {/* Clearbit Logo API: https://dashboard.clearbit.com/docs?ref=clearbit-blog.ghost.io#logo-api */}
            <Avatar
              variant={'rounded'} alt={selectedCompany.ticker}
              src={'https://logo.clearbit.com/' + selectedCompany.domain + '?size=160'}
              sx={{
                background: (theme: Theme) =>
                  theme.palette.secondary.main
              }}
            >
              <FindInPageTwoToneIcon />
            </Avatar>
          </Grid>
          <Grid item ml={1} mt={0.5}>
            <Typography variant="h3" component="h3" gutterBottom>{selectedCompany.name}</Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>

      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <Container maxWidth="xs" sx={{ marginTop: 2, marginBottom: 0 }}>

                <ToggleButtonGroup
                  value={period}
                  exclusive
                  size={'small'}
                  fullWidth={true}
                  onChange={handlePeriodChange}
                  disabled={section == 'balanceSheet' || section == 'performance'
                    || section == 'investedCapital' || section == 'ruleOf40'
                    || section == 'shares' || section == 'netCashOpExCapEx'
                    || section == 'dividendYield'}>
                  <ToggleButton value="quarterly">Quarterly</ToggleButton>
                  <ToggleButton value="ttm">TTM</ToggleButton>
                </ToggleButtonGroup>

              </Container>

              <Grid container height={550}>
                {section == 'shares' &&
                  <Typography variant="h4" sx={{ ml: 'auto', mr: 'auto', pt: 2, pb: 1 }}>
                    Ideally no increase in past 10 years, stays the same or deceases.
                  </Typography>
                }
                {section == 'netCashOpExCapEx' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }}>
                    Net Cash / (OpEx TTM + CapEx TTM) represents a financial ratio that provides insight into a
                    company's financial health and operational efficiency. A higher ratio suggests the company is in a
                    stronger balance sheet and has better operational efficiency. It's best to compare this ratio
                    against other companies (<Link href="https://x.com/freshjiva/status/1583093721721368576"
                                                   target="_blank" rel="noopener noreferrer"
                                                   color="primary">see Mayur Thaker's post</Link>).
                  </Typography>
                }
                {section == 'ruleOf40' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }}>
                    The Rule of 40 is a guideline often used in the technology and software-as-a-service (SaaS) sectors
                    to evaluate the performance and growth potential of a company. It balances revenue growth and
                    profitability to provide a simple metric for investors and management to assess whether the company
                    is on a healthy growth trajectory (<Link
                    href="https://chatgpt.com/share/ae4e4d14-59d0-4162-9a35-2a5dc8853298"
                    target="_blank" rel="noopener noreferrer"
                    color="primary">see Rule of 40 Overview</Link>).
                  </Typography>
                }
                {section != 'ruleOf40' && section != 'netCashOpExCapEx' && section != 'shares' &&
                  <Typography variant="h4" sx={{ ml: 8, mr: 8, pt: 1, pb: 0 }} height={30}>&nbsp;</Typography>
                }

                <ResponsiveChartContainer
                  xAxis={[{
                    scaleType: 'band',
                    data: section == 'ruleOf40' ? revenues.xaxisLabels.slice(revenues.xaxisLabels.length - 16) : revenues.xaxisLabels,
                    label: 'Quarter'
                  }]}
                  yAxis={[{
                    label: section == 'performance' || section == 'eps' || section == 'margins'
                    || section == 'opExVsCapExMargin' || section == 'dividends' || section == 'dividendYield'
                    || section == 'payoutRatio' || section == 'epsGrowth' || section == 'netCashOpExCapEx'
                    || section == 'ruleOf40' ? ''
                      : 'in millions',
                    valueFormatter: (value: number) => {
                      return (section == 'shares' || section == 'performance' || section == 'margins'
                        || section == 'opExVsCapExMargin' || section == 'dividendYield' || section == 'payoutRatio'
                        || section == 'epsGrowth' || section == 'netCashOpExCapEx' || section == 'ruleOf40' ? '' : '$')
                        + value + (section == 'performance' || section == 'margins' || section == 'opExVsCapExMargin'
                        || section == 'dividendYield' || section == 'payoutRatio' || section == 'epsGrowth'
                        || section == 'ruleOf40' ? '%' : '');
                    }
                  }]}
                  colors={cheerfulFiestaPalette}
                  series={series
                    .map((s) => ({
                      ...s,
                      type: s.type ? s.type : 'bar',
                      data: s.data,
                      highlightScope: { highlighted: 'series', faded: 'global' },
                      valueFormatter: (value: number) => {
                        return (section == 'performance' || section == 'margins' || section == 'opExVsCapExMargin'
                        || section == 'dividendYield' || section == 'payoutRatio' || section == 'epsGrowth'
                        || section == 'ruleOf40' ?
                          FinanceDataPercentageFormatter(s.data, value)
                          : section == 'shares' ?
                            FinanceDataMillionValueFormatter(s.data, value)
                            : section == 'netCashOpExCapEx' ?
                              FinanceDataValueFormatter(s.data, value)
                              : '$' + (section == 'eps' || section == 'dividends' ?
                              FinanceDataValueFormatter(s.data, value)
                              : FinanceDataMillionValueFormatter(s.data, value)));
                      }
                    }))}
                  sx={{
                    ml: 4.5,
                    pl: 1.5,
                    pr: 0.5,
                    pb: 1,
                    mb: section == 'shares' ? 2 : 0,
                    [`.${axisClasses.left} .${axisClasses.label}`]: {
                      transform: 'translate(-30px, 0px)'
                    },
                    [`.${axisClasses.bottom} .${axisClasses.label}`]: {
                      transform: 'translate(0px, 15px)'
                    },
                    [`.${axisClasses.bottom} .${axisClasses.tickLabel}`]: {
                      transform: 'rotate(-45deg) ' + (section == 'ruleOf40' ? 'translate(-7px, 56px)' : 'translate(-17px, 24px)')
                    }
                  }}
                >
                  <BarPlot />
                  <LinePlot />
                  <ChartsAxis />
                  <ChartsLegend />
                  <ChartsTooltip />
                  <ChartsAxisHighlight x={'band'} />
                  {section == 'ruleOf40' &&
                    <ChartsReferenceLine y={40}
                                         label="Rule of 40 Minimum"
                                         labelAlign={'start'}
                                         lineStyle={{
                                           strokeDasharray: '5 5'
                                         }} />
                  }
                </ResponsiveChartContainer>

              </Grid>

              <Grid container height={80} />
              <Grid container height={170}>

                <ToggleButtonGroup
                  value={section}
                  exclusive
                  size={'small'}
                  fullWidth={true}
                  onChange={handleSectionChange}
                  sx={{ marginLeft: 1, marginRight: 1 }}>
                  <ToggleButton value="topline">Topline</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="profit">Profitability</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="margins">Margins</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="cash">Cashflow</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="balanceSheet">Health</ToggleButton>
                </ToggleButtonGroup>

                <Grid container height={2} />

                <ToggleButtonGroup
                  value={section}
                  exclusive
                  size={'small'}
                  fullWidth={true}
                  onChange={handleSectionChange}
                  sx={{ marginLeft: 1, marginRight: 1 }}>
                  <ToggleButton value="performance">Performance</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="eps">EPS</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="shares">Shares</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="equity">Equity</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="investedCapital">Invested Capital</ToggleButton>
                </ToggleButtonGroup>

                <Grid container height={2} />

                <ToggleButtonGroup
                  value={section}
                  exclusive
                  size={'small'}
                  fullWidth={true}
                  onChange={handleSectionChange}
                  sx={{ marginLeft: 1, marginRight: 1 }}>
                  <ToggleButton value="operationalLeverage">Operational Leverage</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="opExVsGrossProfit">OpEx vs. Gross Profit</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="opExDistribution">OpEx Distribution</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="opExVsCapEx">OpEx vs. CapEx</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="opExVsCapExMargin">OpEx vs. CapEx Margin</ToggleButton>
                </ToggleButtonGroup>

                <Grid container height={2} />

                <ToggleButtonGroup
                  value={section}
                  exclusive
                  size={'small'}
                  fullWidth={true}
                  onChange={handleSectionChange}
                  sx={{ marginLeft: 1, marginRight: 1 }}>
                  <ToggleButton value="epsGrowth">EPS Growth</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="netCashOpExCapEx">Net Cash / OpEx + CapEx</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value="ruleOf40">Rule of 40</ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value=""></ToggleButton>
                  <Divider flexItem orientation="vertical" />
                  <ToggleButton value=""></ToggleButton>
                </ToggleButtonGroup>

                <Grid container height={2} />

                {revenues.dividends.length > 0 &&
                  <ToggleButtonGroup
                    value={section}
                    exclusive
                    size={'small'}
                    fullWidth={true}
                    onChange={handleSectionChange}
                    sx={{ marginLeft: 1, marginRight: 1 }}>
                    <ToggleButton value="dividends">Dividends</ToggleButton>
                    <Divider flexItem orientation="vertical" />
                    <ToggleButton value="payoutRatio">Payout Ratio</ToggleButton>
                    <Divider flexItem orientation="vertical" />
                    <ToggleButton value="dividendYield">Dividend Yield</ToggleButton>
                    <Divider flexItem orientation="vertical" />
                    <ToggleButton value=""></ToggleButton>
                    <Divider flexItem orientation="vertical" />
                    <ToggleButton value=""></ToggleButton>
                  </ToggleButtonGroup>}

              </Grid>

              <Grid container height={40} />
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}

export default ChartsRevenue;
