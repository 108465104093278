import React, { FC } from 'react';
import { Chip, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

interface ChildProps {
  label: string;
  value: number;
  unit?: string;
}

const MetricValue: FC<ChildProps> = ({ label, value, unit = '' }) => {
  let colors = useTheme().colors;
  return (
    <Stack direction="row" spacing={3} marginBottom={0.5} width={500}>
      <Chip label={label} variant="filled"
            sx={{ width: 310, justifyContent: 'left', fontWeight: 'bold', pl: 0.5 }} />
      <Chip label={value != -1 ? value.toFixed(2) + unit : 'n/a'} variant="filled"
            sx={{
              width: 100,
              fontWeight: 'bold',
              bgcolor: colors.primary.light
            }} />
    </Stack>
  );
};

export default MetricValue;