import React, {forwardRef, Ref, useState, ReactElement, ChangeEvent, useEffect} from 'react';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  List,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  CircularProgress
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {TransitionProps} from '@mui/material/transitions';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CompanyListItem from "./CompanyListItem";
import { useFetchWithAuth } from '../../../../../auth0/useFetchWithAuth';

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DialogWrapper = styled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }
    
    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`
);

const SearchInputWrapper = styled(TextField)(
  ({theme}) => `
    background: ${theme.colors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`
);

const DialogTitleWrapper = styled(DialogTitle)(
  ({theme}) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`
);

function HeaderSearch() {
  const [openSearchResults, setOpenSearchResults] = useState(true);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchResults) {
        setOpenSearchResults(true);
      }
    } else {
      setOpenSearchResults(false);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [companies, setCompanies] = useState(null);
  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    if (open || companies == null) {
      const fetchData = async () => {
        // TODO implement real search (with searchTerm query parameter)
        const response: any = await fetchWithAuth('/api/companies');
        setCompanies(response);
      };
      fetchData().then();
    }
  }, [open]);

  if (!companies) {
    return (<CircularProgress/>)
  }

  return (
    <>
      <Tooltip arrow title="Search Company">
        <IconButton color="primary" onClick={handleClickOpen}>
          <SearchTwoToneIcon/>
        </IconButton>
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth
        scroll="paper"
        onClose={handleClose}
      >
        <DialogTitleWrapper>
          <SearchInputWrapper
            value={searchValue}
            autoFocus={true}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon/>
                </InputAdornment>
              )
            }}
            placeholder="Search e.g. for: Apple, AAPL, TSLA, Palantir..."
            fullWidth
            label="Stock Search"
          />
        </DialogTitleWrapper>
        <Divider/>

        {openSearchResults && (
          <DialogContent>
            <Box
              sx={{pt: 0, pb: 1}}
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="body2" component="span">
                Search results for{' '}
                <Typography
                  sx={{fontWeight: 'bold'}}
                  variant="body1"
                  component="span"
                >
                  {searchValue}
                </Typography>
              </Typography>
            </Box>
            <Divider sx={{my: 1}}/>
            <List disablePadding>
              <CompanyListItem companies={companies} onClose={handleClose}/>
            </List>
          </DialogContent>
        )}
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
