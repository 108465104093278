import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Theme,
  Container,
  Grid,
  Card,
  Typography,
  Avatar
} from '@mui/material';
import Footer from 'src/components/Footer';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import SuspenseLoader from '../../../components/SuspenseLoader';
import { CompanyContext } from '../../../contexts/CompanyContext';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import MetricValue from './MetricValue';
import { useFetchWithAuth } from '../../../auth0/useFetchWithAuth';

interface Metrics {
  ttmPe: number;
  ttmPs: number;

  forwardPe: number;
  forwardPs: number;

  ttmEpsGrowth: number;
  lastYearEpsGrowth: number;
  currentYearEpsGrowth: number;
  nextYearEpsGrowth: number;

  ttmRevenueGrowth: number;
  currentYearExpRevenueGrowth: number;
  nextYearExpRevenueGrowth: number;

  grossMarginTtm: number;
  netMarginTtm: number;

  peg: number;

  extractedAt: string;
}

function MetricsSearch() {

  const { selectedCompany } = useContext(CompanyContext);
  const [metrics, setMetrics] = useState<Metrics | undefined>(undefined);
  const fetchWithAuth = useFetchWithAuth();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchWithAuth<Metrics>('/api/metrics/search?ticker=' + selectedCompany.ticker); // Replace with your API endpoint
      setMetrics(response);
    };
    fetchData().then();
  }, [selectedCompany.ticker]);

  if (!metrics) {
    return (<SuspenseLoader />);
  }

  return (
    <>
      <Helmet>
        <title>Metrics Search</title>
      </Helmet>

      <PageTitleWrapper>
        <Grid container alignItems="center">
          <Grid>
            {/* Clearbit Logo API: https://dashboard.clearbit.com/docs?ref=clearbit-blog.ghost.io#logo-api */}
            <Avatar
              variant={'rounded'} alt={selectedCompany.ticker}
              src={'https://logo.clearbit.com/' + selectedCompany.domain + '?size=160'}
              sx={{
                background: (theme: Theme) =>
                  theme.palette.secondary.main
              }}
            >
              <FindInPageTwoToneIcon />
            </Avatar>
          </Grid>
          <Grid item ml={1} mt={0.5}>
            <Typography variant="h3" component="h3" gutterBottom>{selectedCompany.name}</Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>

      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Card>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <Typography align={'center'} variant={'h5'} marginBottom={1} width={310}>MANDATORY METRICS</Typography>
                <MetricValue label={'TTM PE'} value={metrics.ttmPe} />
                <MetricValue label={'FORWARD PE'} value={metrics.forwardPe} />
                <MetricValue label={'2 YEAR FORWARD PE'} value={-1} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <MetricValue label={'TTM EPS GROWTH'} value={metrics.ttmEpsGrowth} unit={'%'} />
                <MetricValue label={'CURRENT YR EXPECTED EPS GROWTH'} value={metrics.currentYearEpsGrowth} unit={'%'} />
                <MetricValue label={'NEXT YR EXPECTED EPS GROWTH'} value={metrics.nextYearEpsGrowth} unit={'%'} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <MetricValue label={'TTM REV GROWTH'} value={metrics.ttmRevenueGrowth} unit={'%'} />
                <MetricValue label={'CURRENT YR EXPECTED REV GROWTH'} value={metrics.currentYearExpRevenueGrowth}
                             unit={'%'} />
                <MetricValue label={'NEXT YR EXPECTED REV GROWTH'} value={metrics.nextYearExpRevenueGrowth}
                             unit={'%'} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <MetricValue label={'GROSS MARGIN TTM'} value={metrics.grossMarginTtm} unit={'%'} />
                <MetricValue label={'NET MARGIN TTM'} value={metrics.netMarginTtm} unit={'%'} />
                <MetricValue label={'TTM P/S RATIO'} value={metrics.ttmPs} />
                <MetricValue label={'FORWARD P/S RATIO'} value={metrics.forwardPs} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <Typography align={'center'} variant={'h5'} marginBottom={1} width={310}>ADVANCED METRICS</Typography>
                <MetricValue label={'LAST YR EPS GROWTH'} value={metrics.lastYearEpsGrowth} unit={'%'} />
                <MetricValue label={'PEG RATIO'} value={metrics.peg} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <Typography pl={1} fontSize={11}>Numbers as of {new Date(metrics.extractedAt).toLocaleString('de-CH', {
                  dateStyle: 'medium',
                  timeStyle: 'short'
                })}</Typography>
              </Container>

              <Grid container height={10} />

            </Card>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}

export default MetricsSearch;
