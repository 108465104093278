import React, { FC } from 'react';
import { Chip, Tooltip, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

interface ChildProps {
  label: string;
  values: number[];
  unit?: string;
}

const CompareMetricValue: FC<ChildProps> = ({ label, values, unit = '' }) => {
  let colors = useTheme().colors;
  return (
    <Stack direction="row" spacing={3} marginBottom={0.5} width={700}>
      <Chip label={label} variant="filled"
            sx={{ width: 310, justifyContent: 'left', fontWeight: 'bold', pl: 0.5 }} />
      {values.map((value, index) => (
        // TODO implement tooltip
        // <Tooltip key={label + '-compareValue-tooltip' + index} title="Year 2022: 3.3 vs. 2023: 4.7" placement="left" arrow>
          <Chip key={label + '-compareValue' + index}
                label={value != -1 ? (value ? value.toFixed(2) + unit : '') : 'n/a'}
                variant="filled"
                sx={{
                  width: 100, fontWeight: 'bold',
                  bgcolor: index == 0 ? colors.primary.light : index == 1 ? colors.info.light : colors.warning.light
                }} />
        // </Tooltip>
      ))}
    </Stack>
  );
};

export default CompareMetricValue;