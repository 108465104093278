import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Card, Typography, Stack } from '@mui/material';
import Footer from 'src/components/Footer';
import PageTitleWrapper from '../../../components/PageTitleWrapper';
import CompareMetricValue from './CompareMetricValue';
import SuspenseLoader from '../../../components/SuspenseLoader';
import CompareAutocomplete from './CompareAutocomplete';
import { useFetchWithAuth } from '../../../auth0/useFetchWithAuth';

interface Metrics {
  ttmPe: number;
  ttmPs: number;

  forwardPe: number;
  forwardPs: number;

  ttmEpsGrowth: number;
  lastYearEpsGrowth: number;
  currentYearEpsGrowth: number;
  nextYearEpsGrowth: number;

  ttmRevenueGrowth: number;
  currentYearExpRevenueGrowth: number;
  nextYearExpRevenueGrowth: number;

  grossMarginTtm: number;
  netMarginTtm: number;

  peg: number;

  extractedAt: string;
}

function updateTickerMetrics(company: any, setMetrics: (value: (((prevState: Metrics) => Metrics) | Metrics)) => void,
                             fetchWithAuth: <T>(url: string, options?: RequestInit) => Promise<T>) {
  if (company?.tickerSymbol?.length > 0) {
    const fetchData = async () => {
      try {
        const response = await fetchWithAuth<Metrics>('/api/metrics/search?ticker=' + company.tickerSymbol);
        setMetrics(response);
      } catch (error) {
        setMetrics(undefined);
      }
    };
    fetchData().then();
  }
}

function getCompanyFromLocalStorage(key: string) {
  return () => localStorage.getItem(key) != null ? JSON.parse(localStorage.getItem(key)) : null;
}

function MetricsCompare() {

  const [companies, setCompanies] = useState([]);
  const fetchWithAuth = useFetchWithAuth();

  // noinspection DuplicatedCode
  useEffect(() => {
    const fetchData = async () => {
      const response: any = await fetchWithAuth('/api/companies');
      setCompanies(response);
    };
    fetchData().then();
  }, []);

  if (!companies) {
    return (<SuspenseLoader />);
  }

  const [compareCompany1, setCompareCompany1] = useState(getCompanyFromLocalStorage('compareCompany1'));
  const [compareCompany2, setCompareCompany2] = useState(getCompanyFromLocalStorage('compareCompany2'));
  const [compareCompany3, setCompareCompany3] = useState(getCompanyFromLocalStorage('compareCompany3'));

  const [metrics1, setMetrics1] = useState<Metrics | undefined>(undefined);
  const [metrics2, setMetrics2] = useState<Metrics | undefined>(undefined);
  const [metrics3, setMetrics3] = useState<Metrics | undefined>(undefined);

  useEffect(() => updateTickerMetrics(compareCompany1, setMetrics1, fetchWithAuth), [compareCompany1]);
  useEffect(() => updateTickerMetrics(compareCompany2, setMetrics2, fetchWithAuth), [compareCompany2]);
  useEffect(() => updateTickerMetrics(compareCompany3, setMetrics3, fetchWithAuth), [compareCompany3]);

  return (
    <>
      <Helmet>
        <title>Metrics Comparison</title>
      </Helmet>

      <PageTitleWrapper>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              Compare Metrics
            </Typography>
          </Grid>
        </Grid>
      </PageTitleWrapper>

      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Card>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <Stack direction="row" spacing={3} mb={0.5} ml={37.4} width={500}>

                  <CompareAutocomplete companies={companies} selectedCompany={compareCompany1}
                                       onChange={(value) => {
                                         setCompareCompany1(value);
                                         localStorage.setItem('compareCompany1', JSON.stringify(value));
                                       }} />

                  <CompareAutocomplete companies={companies} selectedCompany={compareCompany2}
                                       onChange={(value) => {
                                         setCompareCompany2(value);
                                         localStorage.setItem('compareCompany2', JSON.stringify(value));
                                       }} />

                  <CompareAutocomplete companies={companies} selectedCompany={compareCompany3}
                                       onChange={(value) => {
                                         setCompareCompany3(value);
                                         localStorage.setItem('compareCompany3', JSON.stringify(value));
                                       }} />
                </Stack>
              </Container>

              {/* TODO mandatory vs. advanced metrics */}
              {/* TODO metrics: ... */}
              {/* TODO show market cap + price on top like Jeremy */}
              {/* TODO find way to update stock price + market cap more often */}
              {/* TODO fix responsiveness for iPad */}

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <Typography align={'center'} variant={'h5'} marginBottom={1} width={310}>MANDATORY METRICS</Typography>
                <CompareMetricValue label={'TTM PE'} values={[metrics1?.ttmPe, metrics2?.ttmPe, metrics3?.ttmPe]} />
                <CompareMetricValue label={'FORWARD PE'}
                                    values={[metrics1?.forwardPe, metrics2?.forwardPe, metrics3?.forwardPe]} />
                <CompareMetricValue label={'2 YEAR FORWARD PE'} values={[-1, -1, -1]} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <CompareMetricValue label={'TTM EPS GROWTH'}
                                    values={[metrics1?.ttmEpsGrowth, metrics2?.ttmEpsGrowth, metrics3?.ttmEpsGrowth]}
                                    unit={'%'} />
                <CompareMetricValue label={'CURRENT YR EXPECTED EPS GROWTH'}
                                    values={[metrics1?.currentYearEpsGrowth, metrics2?.currentYearEpsGrowth, metrics3?.currentYearEpsGrowth]}
                                    unit={'%'} />
                <CompareMetricValue label={'NEXT YR EXPECTED EPS GROWTH'}
                                    values={[metrics1?.nextYearEpsGrowth, metrics2?.nextYearEpsGrowth, metrics3?.nextYearEpsGrowth]}
                                    unit={'%'} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <CompareMetricValue label={'TTM REV GROWTH'}
                                    values={[metrics1?.ttmRevenueGrowth, metrics2?.ttmRevenueGrowth, metrics3?.ttmRevenueGrowth]}
                                    unit={'%'} />
                <CompareMetricValue label={'CURRENT YR EXPECTED REV GROWTH'}
                                    values={[metrics1?.currentYearExpRevenueGrowth, metrics2?.currentYearExpRevenueGrowth, metrics3?.currentYearExpRevenueGrowth]}
                                    unit={'%'} />
                <CompareMetricValue label={'NEXT YR EXPECTED REV GROWTH'}
                                    values={[metrics1?.nextYearExpRevenueGrowth, metrics2?.nextYearExpRevenueGrowth, metrics3?.nextYearExpRevenueGrowth]}
                                    unit={'%'} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <CompareMetricValue label={'GROSS MARGIN TTM'}
                                    values={[metrics1?.grossMarginTtm, metrics2?.grossMarginTtm, metrics3?.grossMarginTtm]}
                                    unit={'%'} />
                <CompareMetricValue label={'NET MARGIN TTM'}
                                    values={[metrics1?.netMarginTtm, metrics2?.netMarginTtm, metrics3?.netMarginTtm]}
                                    unit={'%'} />
                <CompareMetricValue label={'TTM P/S RATIO'}
                                    values={[metrics1?.ttmPs, metrics2?.ttmPs, metrics3?.ttmPs]} />
                <CompareMetricValue label={'FORWARD P/S RATIO'}
                                    values={[metrics1?.forwardPs, metrics2?.forwardPs, metrics3?.forwardPs]} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <Typography align={'center'} variant={'h5'} marginBottom={1} width={310}>ADVANCED METRICS</Typography>
                <CompareMetricValue label={'LAST YR EPS GROWTH'}
                                    values={[metrics1?.lastYearEpsGrowth, metrics2?.lastYearEpsGrowth, metrics3?.lastYearEpsGrowth]}
                                    unit={'%'} />
                <CompareMetricValue label={'PEG RATIO'}
                                    values={[metrics1?.peg, metrics2?.peg, metrics3?.peg]} />
              </Container>

              <Container maxWidth="xs" sx={{ ml: 0, marginTop: 2, marginBottom: 0 }}>
                <Typography pl={1} fontSize={11}>Numbers as
                  of {new Date(metrics1?.extractedAt).toLocaleString('de-CH', {
                    dateStyle: 'medium',
                    timeStyle: 'short'
                  })}</Typography>
              </Container>

              <Grid container height={10} />

            </Card>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </>
  );
}

export default MetricsCompare;
