import React, { FC } from 'react';
import { Autocomplete, Popper } from '@mui/material';
import TextField from '@mui/material/TextField';

interface ChildProps {
  companies: any[];
  selectedCompany: any;
  onChange: (value: any[]) => void;
}

const CompareAutocomplete: FC<ChildProps> = ({ companies, selectedCompany, onChange }) => {
  return (
    <Autocomplete
      onChange={(_event, value: any) => {
        onChange(value);
      }}
      value={selectedCompany}
      inputMode={'search'}
      disableClearable
      forcePopupIcon={false}
      options={companies}
      getOptionLabel={c => c.tickerSymbol}
      getOptionKey={c => c.tickerSymbol}
      isOptionEqualToValue={(option, value) => option.tickerSymbol == value.tickerSymbol}
      sx={{ width: 100 }}
      filterOptions={(options, state) =>
        options.filter(option =>
          option.tickerSymbol.toLowerCase().includes(state.inputValue.toLowerCase()) ||
          option.name.toLowerCase().includes(state.inputValue.toLowerCase())
        )}
      renderInput={(params) => <TextField {...params}
                                          placeholder="TICKER"
                                          sx={{
                                            '& .MuiInputBase-input': {
                                              textAlign: 'center',
                                              fontWeight: 'bold'
                                            }
                                          }}
      />}
      renderOption={(props, option) => (
        <li {...props}>{option.name + ' ($' + option.tickerSymbol + ')'}</li>
      )}
      PopperComponent={(props) => <Popper {...props} style={{ width: 235 }} />}
    />
  );
};

export default CompareAutocomplete;