import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useAuth0 } from '@auth0/auth0-react';
import SuspenseLoader from './components/SuspenseLoader';
import React from 'react';

function App() {

  const { isAuthenticated, isLoading, loginWithRedirect, loginWithPopup } = useAuth0();

  if (isLoading) {
    return (<SuspenseLoader />);
  } else if (!isAuthenticated) {
    loginWithRedirect().then();
  } else {

    const content = useRoutes(router);

    return isAuthenticated && (
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {content}
        </LocalizationProvider>
      </ThemeProvider>
    );
  }
}

export default App;
